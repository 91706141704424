<template>
	<v-chip-group mandatory :value="value" @input="$emit('input', $event)" style="height:150px;" class="pa-0 ma-0">
		<v-chip
			v-for="(item, index) in items"
			:class="index == 0 ? $vuetify.rtl? 'mr-8': 'ml-8' : index == items.length - 1 ?$vuetify.rtl? 'ml-8': 'mr-8' : ''"
			@click="$emit('input', item.title)"
			:key="item.title"
			:value="item.title"
			:style="item.title == value ? chosen_chip_style : unchosen_chip_style"
		>
			<v-container>
				<v-row>
					<v-col cols="12" class="pa-0 ma-0 text-center dayofweek">{{ $tr(item.subtitle) }}</v-col>
					<v-col cols="12" class="pa-0 ma-0 mt-2 text-center date">{{ $tr(item.title) }}</v-col>
				</v-row>
			</v-container>
		</v-chip>
	</v-chip-group>
</template>

<script>
	export default {
		mounted() {
			// this.value = this.items[0]?.title
			this.$emit("input", this.items[0]?.title);
		},
		data: () => ({
			chosen_chip_style: `
                color:white;
                height: 100px;
                white-space:normal;
                width: 100px;
                font-family:"Poppins";
                display:flex;
                justify-content:center;
                align-content:center;
                background: linear-gradient(90deg, #006EE9 0%, #105CDB 98.96%);
            `,
			unchosen_chip_style: `
                color: #006EE9;
                white-space:normal;
                height: 70px;
                width: 70px;
                font-family:"Poppins";
                display:flex;
                justify-content:center;
                align-content:center;
                background: #EBF2FF;
            `,
		}),
		props: {
			value: null,
			color: {
				type: String,
				default: "",
			},
			items: {
				type: Array,
				default: () => [
					{
						subtitle: "sun",
						title: 20,
					},
					{
						subtitle: "sun",
						title: 21,
					},
					{
						subtitle: "sun",
						title: 22,
					},
					{
						subtitle: "sun",
						title: 23,
					},
					{
						subtitle: "sun",
						title: 24,
					},
				],
			},
		},
	};
</script>

<style scoped>
	.dayofweek {
		font-family: "Poppins";
		font-size: 20px;
	}
	.date {
		font-display: "Poppins-Bold";
		margin-top: 40px;
		font-size: 24px;
		font-weight: 900;
	}
</style>
